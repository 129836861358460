import { baseRoute } from "@/velta/utils/auxiliar";

const base = baseRoute() ?? 'empresa';

export default function createRoute(title, view, ability = '', breadcrumbText = 'Cadastros', canDownloadExcel = false, canDownloadPDF = false) {

    const meta = {
        pageTitle: title,
        navActiveLink: view,
        resource: ability,
        canDownloadExcel: canDownloadExcel,
        canDownloadPDF: canDownloadPDF,
        breadcrumb: [
            {
                text: breadcrumbText,
                active: true,
            },
            {
                text: title,
                active: true,
            },
        ],
    };

    const beforeEnter = (to, from, next) => {
        if (to.params.id) {
            if (!/^\d+$/.test(to.params.id)) {
                next({ name: view });
            }
        }

        next();
    }

    return [
        {
            path: `/sistema/${base}/${view}`,
            name: view,
            component: () => import(`@/views/${base}/${view}/List.vue`),
            meta: {
                ...meta,
                action: 'read',
            },
        },
        {
            path: `/sistema/${base}/${view}/c`,
            name: `${view}Create`,
            component: () => import(`@/views/${base}/${view}/Form.vue`),
            beforeEnter,
            meta: {
                ...meta,
                action: 'create',
                breadcrumb: [
                    ...meta['breadcrumb'],
                    {
                        text: 'Novo Registro',
                        active: true,
                    }
                ],
            }
        },
        {
            path: `/sistema/${base}/${view}/r/:id`,
            name: `${view}Read`,
            component: () => import(`@/views/${base}/${view}/Form.vue`),
            beforeEnter,
            meta: {
                ...meta,
                action: 'read',
                breadcrumb: [
                    ...meta['breadcrumb'],
                    {
                        text: 'Visualizar',
                        active: true,
                    }
                ]
            }
        },
        {
            path: `/sistema/${base}/${view}/u/:id`,
            name: `${view}Update`,
            component: () => import(`@/views/${base}/${view}/Form.vue`),
            beforeEnter,
            meta: {
                ...meta,
                action: 'update',
                breadcrumb: [
                    ...meta['breadcrumb'],
                    {
                        text: 'Editar',
                        active: true,
                    }
                ]
            }
        }
    ];
}
