export default [
    {
        path: '/sistema/cliente/',
        name: 'home-client',
        component: () => import('@/views/cliente/index/Index.vue'),
        meta: {
            pageTitle: 'Página Inicial',
            resource: 'any',
            breadcrumb: [
                {
                    text: 'Página Inicial',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/sistema/cliente/agendamento',
        name: 'agendamento',
        component: () => import('@/views/cliente/agendamento/Index.vue'),
        meta: {
            pageTitle: 'Agendamento',
            contentClass: 'ecommerce-application',
            resource: 'any',
            navActiveLink: 'agendamento',
            breadcrumb: [
                {
                    text: 'Agendamento',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/sistema/cliente/agendamento/detalhe/:cpfcnpj/:app?',
        name: 'agendamentoDetalhe',
        component: () => import('@/views/cliente/agendamento/Detalhe.vue'),
        meta: {
            pageTitle: 'Agendamento Detalhe',
            contentClass: 'ecommerce-application',
            resource: 'auth',
            navActiveLink: 'agendamento',
            breadcrumb: [
                {
                    text: 'Agendamento',
                    active: true,
                },
                {
                    text: 'Detalhe',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/sistema/cliente/agendamento/detalhe/:cpfcnpj/checklist/:id?',
        name: 'agendamentoDetalheChecklist',
        component: () => import('@/views/cliente/ChecklistPublic.vue'),
        meta: {
            pageTitle: 'Checklist de Veículo',
            contentClass: 'ecommerce-application',
            resource: 'auth',
            navActiveLink: 'agendamento',
            breadcrumb: [
                {
                    text: 'Agendamento',
                    active: true,
                },
                {
                    text: 'Checklist',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/sistema/cliente/configuracoes/:step?',
        name: 'configuracoes-client',
        component: () => import('@/views/cliente/configuracoes/Index.vue'),
        meta: {
            pageTitle: 'Configurações',
            resource: 'any',
            navActiveLink: 'configuracoes',
            breadcrumb: [
                {
                    text: 'Configurações',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/sistema/cliente/cadastro',
        name: 'cadastro',
        component: () => import('@/views/cliente/cadastro/Cadastro.vue'),
        meta: {
            pageTitle: 'Cadastrar',
            resource: 'auth',
            layout: 'full',
        },
    },
    {
        path: '/sistema/cliente/login',
        name: 'login-client',
        component: () => import('@/views/cliente/login/Login.vue'),
        meta: {
            pageTitle: 'Acessar Sistema',
            resource: 'auth',
            layout: 'full',
        },
    },
    {
        path: '/sistema/cliente/esqueciminhasenha',
        name: 'esqueciminhasenha-client',
        component: () => import('@/views/cliente/ForgotPassword.vue'),
        meta: {
            resource: 'auth',
            layout: 'full',
        },
    },
]
