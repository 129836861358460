import storeModule from './storeModule';
import store from '@/store'
import {initialAbility} from '@/libs/acl/config';
import ability from '@/libs/acl/ability';
import {canNavigate} from '@/libs/acl/routeProtection'
import {baseRoute} from '@/velta/utils/auxiliar';

const validateAuthRoute = async (to, from, next) => {
    const base = baseRoute();

    if (['agendamentoDetalheChecklist', 'agendamentoDetalhe'].includes(to.name) || (['agendamentoDetalheChecklist', 'agendamentoDetalhe'].includes(from.name) && base === 'cliente')) {
        await store.dispatch('auth/fetchAuthUserCliente')
        return next()
    }

    if (['site-home', 'site-home-lavarapido', 'site-home-lavajato', 'site-home-esteticaautomotiva', 'site-home-mecanica', 'site-home-chapeacao', 'site-home-funilaria', 'site-home-borracharia'].indexOf(to.name) !== -1) {
        return next()
    }

    if (['login-client', 'login-company', 'esqueciminhasenha-company', 'esqueciminhasenha-client', 'cadastrolead', 'cadastroempresa', 'buscador', 'termodeuso', 'politicaprivacidade', 'cadastro', 'excluir-conta', 'login-geral'].indexOf(to.name) !== -1) {
        ability.update(initialAbility);
        if (store.getters['auth/authenticated'] || (to.name === 'login-client' || to.name === 'login-company')) {
            switch (base) {
                case 'empresa':
                    await store.dispatch('auth/logout')
                    break;
                case 'cliente':
                    await store.dispatch('auth/logoutCliente')
                    break;
            }
        }
    } else if (!store.getters['auth/authenticated']) {
        switch (base) {
            case 'empresa':
                await store.dispatch('auth/fetchAuthUser')
                break;
            case 'cliente':
                await store.dispatch('auth/fetchAuthUserCliente')
                break;
        }

        if (!store.getters['auth/authenticated']) {
            if (base === 'cliente') {
                return next({
                    name: 'login-client',
                    query: {
                        r: to.fullPath
                    }
                })
            }
            return next({
                name: 'login-company',
                query: {
                    r: to.fullPath
                }
            })
        } else {
            ability.update(store.getters['auth/abilities'])
        }
    } else if (base === 'empresa') {
        const empresaData = store.getters['auth/empresaData']

        const tenantConfig = store.getters['auth/tenantConfig']
        const routeConfiguration = ['initialConfiguration'].indexOf(to.name) !== -1
        const faltaFazerConfiguracoesIniciais = (!tenantConfig.conf_configuracoes || !tenantConfig.conf_servicos || !tenantConfig.conf_vagas)
        if (faltaFazerConfiguracoesIniciais) {
            if (empresaData && !routeConfiguration) {
                return next({
                    name: 'initialConfiguration'
                })
            }
        } else {
            const routeConfigurationWhatsappSuporte = ['initialConfigurationWhatsappSuporte'].indexOf(to.name) !== -1
            const possuiWhatsappDeSuporteConfigurado = empresaData.whatsapp_suporte ?? false

            if (empresaData && !routeConfigurationWhatsappSuporte && !possuiWhatsappDeSuporteConfigurado) {
                return next({
                    name: 'initialConfigurationWhatsappSuporte'
                })
            }
        }
    }


    if (!canNavigate(to)) {
        return next({
            name: 'naoAutorizado'
        })
    }

    return next();
}

export { validateAuthRoute, storeModule };
