import {extend, localize} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules'
import pt_BR from 'vee-validate/dist/locale/pt_BR.json'

const allRules = {
    ...rules,
}

allRules['password'] = {
    validate: (value) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d.*\d)(?=.*[~@#$%^&*+=`|{}:;!.?\"()\[\]-]).{8,60}$/.test(value),
    message: 'Requerimentos não atendidos',
}

allRules['password_confirmed'] = {
    validate: (value, _a) => String(value) === String(_a.target),
    message: 'As senhas não correspondem',
    params: [
        {
            name: 'target',
            isTarget: true
        }
    ]
}

allRules['placa_carro'] = {
    validate: (value) => /^[a-zA-Z]{3} \d(\d{2}|\d[a-zA-Z]|[a-zA-Z]\d)\d$/.test(value),
    message: 'Placa inválida',
}

allRules['telefone'] = {
    validate: (value) => /^(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})$/.test(value),
    message: 'Telefone inválido'
}

allRules['cep'] = {
    validate: (value) => /^\d{5}-\d{3}$/.test(value),
    message: 'CEP inválido'
}

allRules['estado_extenso'] = {
    validate: (value) => ['Acre', 'Alagoas', 'Amazonas', 'Amapá', 'Bahia', 'Ceará', 'Distrito Federal', 'Espírito Santo', 'Goiás', 'Maranhão', 'Minas Gerais', 'Mato Grosso do Sul', 'Mato Grosso', 'Pará', 'Paraíba', 'Pernambuco', 'Piauí', 'Paraná', 'Rio de Janeiro', 'Rio Grande do Norte', 'Rondônia', 'Roraima', 'Rio Grande do Sul', 'Santa Catarina', 'Sergipe', 'São Paulo', 'Tocantins'].includes(value),
    message: 'Estado inválido'
}

allRules['cpf'] = {
    validate: (value) => isValidCpf(value),
    message: 'CPF inválido'
}

allRules['cnpj'] = {
    validate: (value) => isValidCnpj(value),
    message: 'CNPJ inválido'
}

allRules['cpfOrCnpj'] = {
    validate: (value) => isValidCnpj(value) || isValidCpf(value),
    message: 'CPF/CNPJ inválido'
}

allRules['time_validator'] = {
    validate: (value, min) => {
        if (Array.isArray(value) || value === null || value === undefined || value === '') {
          return false;
        }
        return value > min;
    },
    message: 'Valor inferior ao mínimo permitido'
}

allRules['url'] = {
    validate: (value, min) => {
        if (value) {
            return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value);
        }
        return false;
    },
    message: 'Valor informado não é uma URL válida'
}

Object.keys(allRules).forEach((rule) => {
    extend(rule, allRules[rule]);
})

pt_BR.messages['required'] = 'Campo obrigatório';
/* change language */
localize('pt_BR', { messages: pt_BR.messages });

const isValidCpf = (value) => {
    value = value.replace(/[^0-9]/g,'')
    let sum = 0
    for (let i = 0; i < 9; i++) {
        sum += parseInt(value.charAt(i)) * (10 - i)
    }
    let digit1 = sum % 11 < 2 ? 0 : 11 - (sum % 11)

    sum = 0
    for (let i = 0; i < 10; i++) {
        sum += parseInt(value.charAt(i)) * (11 - i)
    }
    let digit2 = sum % 11 < 2 ? 0 : 11 - (sum % 11)

    return parseInt(value.charAt(9)) === digit1 && parseInt(value.charAt(10)) === digit2
}

const isValidCnpj = (value) => {
    value = value.replace(/[^0-9]/g,'')
    let sum = 0
    let pos = 5

    for (let i = 0; i < 12; i++) {
        sum += parseInt(value.charAt(i)) * pos
        pos = pos === 2 ? 9 : pos - 1
    }

    let digit1 = sum % 11 < 2 ? 0 : 11 - (sum % 11)

    sum = 0
    pos = 6

    for (let i = 0; i < 13; i++) {
        sum += parseInt(value.charAt(i)) * pos
        pos = pos === 2 ? 9 : pos - 1
    }

    let digit2 = sum % 11 < 2 ? 0 : 11 - (sum % 11)

    return parseInt(value.charAt(12)) === digit1 && parseInt(value.charAt(13)) === digit2
}
