import Vue from 'vue'
import VueRouter from 'vue-router'
import { validateAuthRoute } from '@/velta/components/auth'
import routesEmpresa from './companyRoutes.js'
import routesCliente from './clientRoutes.js'
import routesSite from './siteRoutes.js'
import useAppConfig from '@core/app-config/useAppConfig'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    scrollBehavior() {
        return {
            x: 0,
            y: 0,
        }
    },
    routes: [
        ...routesEmpresa,
        ...routesCliente,
        ...routesSite,
    ]
})

router.beforeEach((to, from, next) => {
    const { skin } = useAppConfig()

    const isHomeSite = ['site-home', 'site-home-lavarapido', 'site-home-lavajato', 'site-home-esteticaautomotiva', 'site-home-mecanica', 'site-home-chapeacao', 'site-home-funilaria', 'site-home-borracharia'].includes(to.name)

    if (skin.value === 'dark' && !isHomeSite) {
        document.body.classList.add('dark-layout')
    } else {
        document.body.classList.remove('dark-layout')
    }

    Vue.nextTick(() => {
        let description = 'VeltaCar é um sistema para gestão de estéticas automotivas, lavagens de carros, chapeação e pintura. O sistema realiza emissão de nota fiscal, agendamentos, cadastro de clientes, orçamentos e mais.'
        let keywords = 'velta, veltacar, sistema, gestão, software, plataforma, estética automotiva, lava-jato, lavagem automotiva, chapeação, pintura, nota fiscal, agendamentos'
        let title = 'VeltaCar - Sistema de Gestão Automotiva'

        if (isHomeSite) {
            const entity = to.meta?.entity ?? "Automotiva"
            if (entity !== 'Automotiva') {
                title = `Sistema de Gestão para ${entity} - VeltaCar`
                description = `VeltaCar é um sistema para gestão para ${entity}. O sistema realiza emissão de nota fiscal, agendamentos, cadastro de clientes, orçamentos e mais.`
                keywords = `${entity}, velta, veltacar, sistema, software, plataforma, gestão, nota fiscal, agendamentos`
            }
        } else {
            title = (!!to.meta.pageTitle ? to.meta.pageTitle + ' | ' : '') + `VeltaCar - Sistema de Gestão Automotiva`
        }

        let metaDescriptionTag = document.querySelector('meta[name="description"]');
        let metaKeywordsTag = document.querySelector('meta[name="keywords"]');

        if (metaDescriptionTag) {
            metaDescriptionTag.content = description;
        }

        if (metaKeywordsTag) {
            metaKeywordsTag.content = keywords;
        }
        document.title = title
    });

    return validateAuthRoute(to, from, next)
})

router.afterEach(() => {
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
