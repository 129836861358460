import {baseRoute} from "@/velta/utils/auxiliar";

const base = baseRoute();

export default function createRoute(title, view, ability = '') {
    return [
        {
            path: `/sistema/empresa/${view.toLowerCase()}`,
            name: view.toLowerCase(),
            component: () => import(`@/views/${base}/${view}.vue`),
            meta: {
                pageTitle: title,
                navActiveLink: view.toLowerCase(),
                resource: ability,
                action: 'read',
                breadcrumb: [
                    {
                        text: 'Relatórios',
                        active: true,
                    },
                    {
                        text: title,
                        active: true,
                    },
                ],
            },
        },
    ];
}
