<template>
    <div
        v-if="isMobile && notIsApp"
        class="menu-header-dowload-app"
    >
        <img src="https://cdn.veltacar.com.br/logo/veltacar.png" alt="Logo VeltaCar">
        <span>
            Instale o app e <strong>agende horários</strong> de um jeito mais simples e rápido.
        </span>
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click="openAppDownload"
        >
            Instalar
        </b-button>
    </div>
</template>
<script>
import { BButton } from "bootstrap-vue";
import Ripple from 'vue-ripple-directive'

export default {
    name: "WarningDownloadApp",
    components: { BButton },
    directives: {
        Ripple,
    },
    data() {
        return {
            isMobile: true,
            notIsApp: false,
        }
    },
    created() {
        this.isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        this.notIsApp = !(window.matchMedia('(display-mode: standalone)').matches || ('standalone' in navigator && (navigator).standalone === true))
    },
    methods: {
        openAppDownload() {
            const userAgent = String(navigator.userAgent || navigator.vendor || window.opera);
            if (/android/i.test(userAgent)) {
                window.open("https://play.google.com/store/apps/details?id=br.com.veltacar.twa&hl=pt_BR", "_blank");
            } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                window.open("https://apps.apple.com/br/app/veltacar/id6449039295?platform=iphone", "_blank");
            }
        }
    }
}
</script>


<style>
.menu-header-dowload-app {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    background-color: #212529;
}

.menu-header-dowload-app button {
    background: #7367f0;
    color: #fff;
    margin: 10px 8px 10px 0;
    border-radius: 5px;
    padding: 5px 15px;
    text-decoration: none;
    font-family: system-ui;
    font-size: 12px;
    font-weight: bold;
    border: none;
    text-align: center;
}

.menu-header-dowload-app span {
    color: #fff;
    font-family: system-ui;
    font-size: 13px;
    font-weight: bold;
}

.menu-header-dowload-app img {
    margin: 10px 0 10px 8px;
    height: 2.5rem;
    width: 2.5rem;
}
@media (min-width: 768px) {
    .menu-header-dowload-app {
        display: none;
    }
}
</style>
