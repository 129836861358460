import store from '@/store'

export const hexToRgb = (hex) => `${parseInt(hex.slice(1, 3), 16)}, ${parseInt(hex.slice(3, 5), 16)}, ${parseInt(hex.slice(5, 7), 16)}`

export const getSize = () => ['xs', 'sm'].includes(store.getters['app/currentBreakPoint']) ? 'lg' : 'md'

export const getValidationState = ({ validated, required: fieldRequired, changed, valid = null }) => !fieldRequired && !changed ? null : (validated ? valid : null)

export const baseRoute = () => {
    const path = window.location.pathname;
    const bases = path.split('/');
    const base = [ 'empresa', 'cliente' ].includes(bases[2]) ? bases[2] : 'empresa';

    return base;
}
