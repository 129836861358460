import Vue from 'vue'

//  axios
import axios from 'axios'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import router from "@/router";

const csrfToken = window.Laravel.csrfToken || document.querySelector('meta[name="csrf-token"]').getAttribute('content')

const axiosIns = axios.create({
    baseURL: window.APP_URL || 'https://veltacar.com.br/',
})

axiosIns.interceptors.response.use(
    response => response,
    error => {
        const { config, response } = error
        const originalRequest = config

        if (response && response.status === 422 && !String(originalRequest.url).includes("auth")) {
            Vue.$toast({
                component: ToastificationContent,
                props: {
                    title: 'VOCÊ FOI DESLOGADO',
                    icon: 'BellIcon',
                    text: 'Acesse sua conta novamente para ter acesso!',
                    variant: 'danger',
                },
            })
            setTimeout(() => {
                router.push({
                    name: 'login-geral',
                }).catch(() => {})
            }, 2000)
        }

        if (response && response.status === 419 && !originalRequest._retry) {
            originalRequest._retry = true;

            return getCsrfToken().then(csrfToken => {
                window.Laravel.csrfToken = csrfToken
                document.querySelector('meta[name="csrf-token"]').setAttribute('content', csrfToken);
                originalRequest.headers['X-CSRF-TOKEN'] = csrfToken;

                return axios(originalRequest);
            });
        }

        return Promise.reject(error)
    },
)

function getCsrfToken() {
    return axios.get('/api/csrf-token')
        .then(response => {
            return response.data.csrfToken;
        })
        .catch(error => {
            console.error('Erro ao obter token CSRF:', error);
            throw error;
        });
}


axiosIns.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken

Vue.prototype.$axios = axiosIns

export default axiosIns
