import axios from '@axios'

export default {
    namespaced: true,
    state: {
        userData: {},
        empresaData: {},
        notifications: [],
        tenantConfig: [],
    },
    mutations: {
        setUserData(state, userData) {
            state.userData = userData;
        },
        setTenantConfig(state, tenantConfig) {
            state.tenantConfig = tenantConfig;
        },
        setEmpresaData(state, empresaData) {
            state.empresaData = empresaData;
        },
        setNotifications(state, notifications) {
            state.notifications = notifications;
        },
        setMarkAllAsReadNotification(state) {
            state.notifications.forEach(notification => {
                notification.lido = 1;
            });
        },
        setMarkAsReadNotificationById(state, id) {
            const notificationIndex = state.notifications.findIndex(notification => notification.id === id);
            if (notificationIndex !== -1) {
                state.notifications[notificationIndex].lido = 1;
            }
        }
    },
    getters: {
        authenticated(state) {
            return Object.keys(JSON.parse(JSON.stringify(state.userData))).length > 0
        },
        tenantConfig(state) {
            return state.tenantConfig
        },
        empresaData(state) {
            return state.empresaData
        },
        abilities(state) {
            return [
                {
                    subject: 'any'
                },
                ...state.userData['permissoes']
            ]
        }
    },
    actions: {
        async logout(ctx) {
            await axios.get('/api/auth/logout').then(() => ctx.commit('setUserData', {})).catch(() => ctx.commit('setUserData', {}))
        },
        async logoutCliente(ctx) {
            await axios.get('/api/cliente_geral/logout').then(() => ctx.commit('setUserData', {})).catch(() => ctx.commit('setUserData', {}))
        },
        login(ctx, data) {
            return axios.get('/sanctum/csrf-cookie').then((res) => {
                return axios({
                    method: 'POST',
                    url: '/api/auth/login',
                    validateStatus: (status) => status === 201,
                    data
                }).then(() => {
                    return ctx.dispatch('fetchAuthUser');
                })
            });
        },
        loginCliente(ctx, data) {
            return axios.get('/sanctum/csrf-cookie').then((res) => {
                return axios({
                    method: 'POST',
                    url: '/api/cliente_geral/login',
                    validateStatus: (status) => status === 201,
                    data
                }).then(() => {
                    return ctx.dispatch('fetchAuthUserCliente');
                })
            });
        },
        redefinirsenha(ctx, data) {
            return axios.get('/sanctum/csrf-cookie').then((res) => {
                return axios({
                    method: 'POST',
                    url: '/api/auth/redefinirsenha',
                    validateStatus: (status) => status === 201,
                    data
                });
            });
        },
        redefinirSenhaCliente(ctx, data) {
            return axios.get('/sanctum/csrf-cookie').then((res) => {
                return axios({
                    method: 'POST',
                    url: '/api/cliente_geral/redefinirSenha',
                    validateStatus: (status) => status === 201,
                    data
                });
            });
        },
        async fetchAuthUser(ctx) {
            return await axios({
                method: 'GET',
                url: '/api/auth/fetchAuthUser',
                validateStatus: (status) => status === 200
            }).then((response) => {
                ctx.commit('setUserData', response.data.userData);
                ctx.commit('setEmpresaData', response.data.empresaData)
                ctx.commit('setNotifications', response.data.notifications);
                ctx.commit('setTenantConfig', response.data.tenantConfig);
            }).catch((error) => {
                ctx.commit('setUserData', {});
                ctx.commit('setEmpresaData', {});
                ctx.commit('setNotifications', {});
                ctx.commit('setTenantConfig', {});
            });
        },
        async afterUpdateOrCreate(ctx, data) {
            return await axios({
                method: 'POST',
                url: '/api/usuario/afterUpdateOrCreate',
                validateStatus: (status) => status === 200,
                data,
            })
        },
        async fetchAuthUserCliente(ctx) {
            return await axios({
                method: 'GET',
                url: '/api/cliente_geral/fetchAuthUser',
                validateStatus: (status) => status === 200
            }).then((response) => {
                ctx.commit('setUserData', response.data.userData);
                ctx.commit('setNotifications', response.data.notifications);
            }).catch((error) => {
                ctx.commit('setUserData', {});
                ctx.commit('setNotifications', {});
            });
        },
        markAllAsRead(ctx) {
            ctx.commit('setMarkAllAsReadNotification');
        },
        markNotificationAsRead(ctx, id) {
            ctx.commit('setMarkAsReadNotificationById', id);
        }
    }
}
